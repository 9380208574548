import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components"
import Logo from "../images/logo.svg"

const ThanksPage = () => (
  <Layout>
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8">
          <img className="h-24 mx-auto" src={Logo} alt="Logo" />
        </div>
        <h2 className="text-4xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
          Thank you for choosing to work with Elite Home Inspections.
          <br />
          We've received your Quote and will be in touch soon.
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Back Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThanksPage
